import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useLocation } from "@reach/router";
import { Link } from "gatsby"
import { Icon } from "react-icons-kit"
import { iosBookOutline } from 'react-icons-kit/ionicons/iosBookOutline'
import { androidCamera } from 'react-icons-kit/ionicons/androidCamera'

import { DrawerContext } from '../../contexts/DrawerContext';

const ScrollSpyMenu = ({ className, menuItems, drawerClose, ...props }) => {
  const { dispatch } = useContext(DrawerContext);
  // empty array for scrollspy items
  const scrollItems = [];

  // convert menu path to scrollspy items
  menuItems.forEach(item => {
    scrollItems.push(item.path.slice(1).replace("#", ""));
  });

  // Add all classs to an array
  const addAllClasses = ['scrollspy__menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };
  return (
    <Scrollspy
      items={scrollItems}
      className={addAllClasses.join(' ')}
      drawerClose={drawerClose}
      {...props}
    >
      {menuItems.map((menu, index) => (
        <li key={`menu-item-${index}`}>
          {drawerClose ? (
            <ScrollSpyLink
              href={menu.path}
              label={menu.label}
              offset={menu.offset}
              onClick={toggleDrawer}
            />
          ) : (
              <ScrollSpyLink href={menu.path} label={menu.label} offset={menu.offset} />
            )}
        </li>
      ))}
    </Scrollspy>
  );
};

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
};

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current',
};

export default ScrollSpyMenu;

const ScrollSpyLink = ({ href, label, ...rest }) => {
  const location = useLocation();
  const base = "https://www.repairsetup.com"
  const url = new URL(href, base)
  const pathname = url.pathname.trim()
  if (url.origin === base && pathname === location.pathname) {
    // If url.origin and base are the same, probably it is default base,
    // so it was missing in the original href
    const h = href.replace(pathname, "")
    return <AnchorLink href={h} {...rest}>
      {label}
    </AnchorLink>
  }

  let icon = null
  if (label === "Documentation") {
    icon = <Icon icon={iosBookOutline} size={32} />
  } else if (label === "Video Tutorials") {
    icon = <Icon icon={androidCamera} size={32} />
  }
  // Is it local link. E.g. without hostname
  if (href[0] === "/") {
    return <Link to={href}>{icon}{label}</Link>
  }
  return <a href={href}>{label}</a>
}