import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from "react-icons-kit"
import { mail } from 'react-icons-kit/ikons/mail'
import { user } from 'react-icons-kit/ikons/user'
import { ic_close } from 'react-icons-kit/md/ic_close'

import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Logo from 'common/src/components/UIElements/Logo';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Search from "../../../components/Search"

import LogoImage from 'common/src/assets/image/saas/logo-full.svg';

const IconButton = styled(Button)`
  color: gray;
  &:hover {
    color: #008abe;
  }
`

const Navbar = ({ navbarStyle, logoStyle, btnStyle, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);
  const [menuVisible, setMenuVisible] = useState(true)

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const onSearch = visible => {
    setMenuVisible(!visible)
  }

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container fullWidth noMobileGutter>
        <Box {...row}>
          <Logo
            href="/#"
            logoSrc={LogoImage}
            title="RepairSetup"
            logoStyle={logoStyle}
            logoWrapperStyle={{ mr: '0px' }}
          />
          <Box {...menuWrapper}>
            <div style={{ flexGrow: 1 }} />
            {menuVisible && <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.saasJson.MENU_ITEMS}
              offset={-100}
            />}
            <div style={{ flexGrow: 1 }} />

            <Search hitsTop="80px" onToggle={onSearch} />
            <Link to="/contact-us">
              <IconButton
                variant="textButton"
                icon={<Icon icon={mail} size={32} />}
                aria-label="contact us"
              />
            </Link>
            <a href="https://app.repairsetup.com">
              <Button title="LOGIN" {...btnStyle}
                icon={<Icon icon={user} size={24} />}
                iconPosition="left"
              />
            </a>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#008abe" open={state.isOpen} />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
              closeButton={<Icon icon={ic_close} size={32} />}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.saasJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      'flex-start',
    ],
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['100px', '130px', '130px', '130px'],
    width: "auto",
    pt: '10px',
    pb: '10px',
    mr: [0, 0, 0, '36px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
    width: "100%",
  },
  btnStyle: {
    minWidth: ['80px', '80px', '80px', '80px'],
    minHeight: '32px',
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    px: '5px',
    py: '0px',
    ml: '10px',
    colors: 'secondaryWithBg',
  },
};

export default Navbar;
